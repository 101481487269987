export function objToFormData(obj, form = new FormData(), parentKey = '') {
  if (obj === null || obj === undefined) return form

  // Iterate over the object's properties
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key]
      const fullKey = parentKey ? `${parentKey}[${key}]` : key

      if (value instanceof File) {
        // Handle File objects
        form.append(fullKey, value)
      } else if (value instanceof FileList) {
        // Handle FileList by adding each file
        Array.from(value).forEach((file, index) =>
          form.append(`${fullKey}[${index}]`, file)
        )
      } else if (Array.isArray(value)) {
        // Handle Arrays (can contain objects, primitives, or files)
        value.forEach((element, index) => {
          const arrayKey = `${fullKey}[${index}]`
          if (typeof element === 'object' && element !== null) {
            objToFormData(element, form, arrayKey)
          } else {
            form.append(arrayKey, element)
          }
        })
      } else if (typeof value === 'object' && value !== null) {
        // Handle nested objects
        objToFormData(value, form, fullKey)
      } else {
        // Handle primitive data types (string, number, boolean)
        form.append(fullKey, value)
      }
    }
  }

  return form
}
